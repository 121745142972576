.main-header {
  height: 90px;
  position: relative;
  background-color: #008265;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 310px;
  padding-right: 50px;
  @media (max-width: 1260px) {
    padding-left: 240px;
  }
  .logo-menu {
    display: flex;
    align-items: center;
    height: 100%;
    .logo-wrap {
      color: #fff;
      font-weight: bold;
      font-size: 28px;
      margin-right: 120px;
      position: absolute;
      left: -10px;
      z-index: 800;
      top: -32px;
      margin-right: 70px;
      img {
        max-width: 270px;
      }
    }
    
    .nav-menu {
      height: 100%;
      display: flex;
      @media (max-width: 998px) {
          display: none;
        }
      ul {
        display: flex;
        align-items: center;
        justify-content: center;
        li {
          margin-right: 10px;
          a {
            font-size: 16px;
            color: #fff;
            padding:7px 10px;
            @media (max-width: 1199px) {
              font-size: 14px;
            }
            @media (max-width: 1098px) {
                padding: 3px 5px;
            }
            &:hover {
              background-color: #ED5F5F;
              border-radius: 5px;
            }
            &.active {
              background-color: #ED5F5F;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
  .social-language {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 998px) {
        display: none;
      }
    .social-head-wrap {
        margin-right: 100px;
        @media (max-width: 1315px) {
          margin-right: 40px;
        }
        @media (max-width: 1199px) {
          margin-right: 30px;
        }
        @media (max-width: 1050px) {
          margin-right: 10px;
        }
        @media (min-width:999px) and (max-width: 1028px) {
          display: none;
        }
      .social-img {
          max-width: 22px;
          margin-right: 20px;
      }
    }
  }
  .mobile-menu {
    position: absolute;
    top: 90px;
    display: none;
    background: #008265;
    width: 100%;
    left: 0;
    z-index: 50;
    &.active {
      display: block;
    }
    ul {
      li {
        text-align: center;
        margin-bottom: 15px;
        a {
          color: #fff;
          font-weight: 600;
        }
      }
    }
  }
}

.language-changer {
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  .ru {
    position: absolute;
    bottom: 5px;
    color: #fff;
    display: none;
    &.active {
      display: block;
    }
  }
}

.main-hero-section {
  min-height: 400px;
  background-image: url('../img/agro1.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  &.product-main-1 {
    background-image: url('../img/product1.jpg');
  }
  &.product-main-2 {
    background-image: url('../img/product2.jpg');
  }
  &.product-main-3 {
    background-image: url('../img/product3.jpg');
  }
  &.product-main-4 {
    background-image: url('../img/product4.jpg');
  }
  &.product-main-5 {
    background-image: url('../img/blue.jpg');
  }
  &.product-main-6 {
    background-image: url('../img/ovochi.jpg');
  }
  &.product-main-7 {
    background-image: url('../img/product7.jpg');
  }
  &.agro2 {
    background-image: url('../img/agro2.jpg');
    .left-fixed-label {
      .sub-title {
          font-size: 14px;
          @media (max-width: 768px) {
            font-size: 12px;
          }
      }
      .title {
          line-height: 30px;
          font-size: 28px;
        @media (max-width: 768px) {
          line-height: 19px;
          font-size: 16px;
        }
      }
    }
  }
  &.agro3 {
    background-image: url('../img/agro3.jpg');
  }
  &.agro4 {
    background-image: url('../img/agro4.jpg');
  }
  &.contact-main {
    background-image: url('../img/IMG_0217.jpg');
  }
  &.contact-main-quality {
    background-image: url('../img/company-bg-quality.png');
  }
  &.contact-main-eco {
    background-image: url('../img/ecology.jpg');
  }
  &.techno-main {
    background-image: url('../img/bobs.jpg');
  }
  &.product-main {
    background-image: url('https://img3.goodfon.ru/wallpaper/nbig/7/7e/aziya-pole-trava-agrokultura.jpg');
  }
  &.blog-bg {
    background-image: url('../img/blogbg.jpg');
  }
}

.left-fixed-label {
  height: 110px;
  max-width: 680px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  bottom: 80px;
  width: 100%;
  transition: .3s ease;
  color: #fff;
  background-color: rgba(24, 167, 57, 0.7);
  padding-left: 30px;
  padding-right: 30px;
  // transform: translate(-100%);
  @media (max-width: 768px) {
     height: auto;
     padding-bottom: 10px;
     padding-top: 10px;
     max-width: 480px;
    }
  // &.active {
  //   transform: translate(0%);
  // }
  .txt-wrap {
    .title {
      font-size: 32px;
      font-weight: bold;
      @media (max-width: 768px) {
        font-size: 24px;
      }
    }
    .sub-title {
      font-size: 20px;
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
  }
  .arrow-wrap {
    .circle {
      background-color:transparent;
      border-radius: 40px;
      width: 40px;
      height: 40px;
      background-size: cover;
      background-position: center center;
      background: url(https://www.ekompany.eu/images/iconen/icoon.headerafbeeldingenlink.png) center no-repeat rgba(11,135,108,0.7);
    }
  }
}

.main-ino-section {
  display: flex;
  max-width: 1380px;
  margin: 0 auto;
  margin-top: 60px;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #E0E0E0;
  padding-bottom: 40px;
  @media (max-width: 998px) {
    flex-direction: column;
  }
  .left-txt {
    color: #313131;
    max-width: 750px;
    padding-right: 100px;
    @media (max-width: 998px) {
      padding-right: 0px;
      max-width: 100%;
    }
    .title {
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 25px;
      @media (max-width: 700px) {
        font-size: 22px;
      }
    }
    .sub-title {
      font-size: 18px;
    }
    &.w800 {
      max-width: 800px;
    }
  }
  .right-label {
    background-color: #008265;
    border-radius: 18px;
    max-width: 485px;
    width: 100%;
    padding: 30px;
        height: 290px;
    // max-height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media (max-width: 1099px) {
      display: flex;
      height: 350px;
      flex-direction: column-reverse;
    }
    @media (max-width: 998px) {
      margin-top: 40px;
    }
    @media (max-width: 550px) {
        justify-content: flex-end;
        height: auto;
    }
    .info-wrap {
      color: #fff;
      max-width: 250px;
      margin-right: 20px;
      @media (max-width: 1099px) {
        max-width: 100%;
        margin-right: 0px;
        margin-top: 10px;
      }
      .ru-blog-title {
        font-size: 22px !important;
      }
      .title {
        font-size: 22px;
        margin-bottom: 15px;
        font-weight: 600;
      }
      .info {
        font-size: 16px;
      }
    }
    .img-box {
      max-width: 120px;
      width: 100%;
      max-height: 180px;
      height: 100%;
      background-size: cover;
      background-image: url('../img/blogbg.jpg');
      background-position: center center; 
      @media (max-width: 1099px) {
          max-width: 220px;
      }
      @media (max-width: 550px) {
        display: none;
      }
    }
    &.blog-label {
      height: auto;
      padding-bottom: 20px;
      padding-top: 15px;
      background: url(../img/blog.png) right 25px top 22px no-repeat #d6ebe6;
      .info-wrap {
          max-width: 100%;
          width: 100%;
        ul {
          li {
              background: url('../img/chevron.png') 13px center no-repeat;
              padding: 10px 0px 10px 30px;
              border-bottom: 1px solid #eee;
            a {
              color: #008164;
              transition: .2s ease;
              font-weight: 500;
            }
            &:hover {
              a {
                color: #ed5f5f;
              }
            }
            &.title-blog {
              background: none;
              font-weight: 700;
              padding-left: 0px;
              font-size: 30px;
              color: #008164;
            }
            &.active {
              a {
                color: #ed5f5f;
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }
  &.border-bottom-none {
    border-bottom: 0px;
  }
}

.main-items-section {
    border-bottom: 1px solid #E0E0E0;
    max-width: 1380px;
    padding-left: 30px;
    padding-right: 30px;
    margin: 0 auto;
    margin-top: 60px;
    padding-bottom: 40px;
  .title {
    font-size: 32px;
    color: #313131;
    font-weight: bold;
    padding-bottom: 35px;
  }
  .items-wrap {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 768px) {
     justify-content: center;
    }
    @media (max-width: 584px) {
      max-width: 400px;
      margin: 0 auto;
    }
    @media (max-width: 384px) {
      max-width: 290px;
      margin: 0 auto;
    }
    .item-label-wrap {
      margin-bottom: 30px;
      @media (max-width: 584px) {
        &:nth-child(2n) {
          .item-box {
            margin-right: 0px;
          }
        }
      }
      @media (max-width: 384px) {
        .item-box {
          margin-right: 0px;
        }
      }
    }
    .item-box {
      margin-right: 25px;
      width: 150px;
      height: 150px;
      border-radius: 6px;
      cursor: pointer;
      
      &:hover {
           box-shadow: 5px 4px 5px rgba(0, 0, 0, 0.22);
      }
      img {
        transition: .2s ease;
        max-width: 150px;
      }
      &.label1 {
        background: url('../img/label1.jpg');
        background-position: center center;
        background-size: cover;
      }
      &.label2 {
        background: url('../img/label2.jpg');
        background-position: center center;
        background-size: cover;
      }
      &.label3 {
        background: url('../img/label3.jpg');
        background-position: center center;
        background-size: cover;
      }
      &.label4 {
        background: url('../img/label4.jpg');
        background-position: center center;
        background-size: cover;
      }
      &.label5 {
        background: url('../img/label5.jpg');
        background-position: center center;
        background-size: cover;
      }
      &.label6 {
        background: url('../img/label6.jpg');
        background-position: center center;
        background-size: cover;
      }
      &.label7 {
        background: url('../img/label7.jpg');
        background-position: center center;
        background-size: cover;
      }
    }
  }
}

.burger-icon {
    max-width: 20px;
    display: none;
    cursor: pointer;
    @media (max-width: 998px) {
      display: block;
    }
}

.drop-drown-li {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  &:hover {
    .drop-menu {
      display: block;
    }
  }
  .drop-menu {
    position: absolute;
    background-color: #ed5f5f;
    z-index: 50;
    top: 90px;
    width: 360px;
    display: none;
    ul {
      flex-flow: column;
      padding-top: 15px;
      padding-bottom: 5px;
      padding-left: 10px;
      align-items: flex-start !important;
      li {
        padding-bottom: 10px;
        a {
          &:hover {
            background-color: transparent;
            border-radius: 0px;
            font-weight: 700;
          }
        }
      }
    }
  }
}

.product-wrap-main {
   padding-top: 10px;
   .table-title {
      font-size: 20px;
      font-weight: 700;
      color: #ef4035;
      margin-top: 20px;
      margin-bottom: 10px;
   }
  .product-box {
      background: url('../img/chevron.png') 13px center no-repeat;
      display: flex;
      border-bottom: 1px solid #c7e4dd;
      padding: 10px 0px;
      color: #1c1c1c;
      font-size: 16px;
      font-weight: 500;
      transition: .3s ease;
      @media (max-width: 550px) {
        flex-direction: column;
        align-items: center;
      }
      &:hover {
        color: #ed5f5f;
      }
    .number {
      display: inline-block;
      margin: 0px 15px 0px 35px;
      width: 65px;
      @media (max-width: 550px) {
       width: auto;
       margin: 0px;
      }
    }
    .formule {
      display: inline-block;
      width: 300px;
      margin-right: 15px;
      @media (max-width: 550px) {
       width: auto;
       margin-right: 0px;
       margin-bottom: 10px;
       margin-top: 10px;
      }
    }
    .title-row {
      display: inline-block;
      width: calc(100% - 380px);
      @media (max-width: 550px) {
       width: auto;
      }
    }
  }
}

.item-label-wrap {
  &:hover {
   .box-name {
      color: #ed5f5f;
    }
  }
  .box-name {
      transition: .2s ease;
      font-size: 18px;
      text-align: center;
      padding-top: 15px;
      color: #528F4C;
      max-width: 150px;
    }
}


.inner-main-product {

}


.blog-list-wrap {
  li {
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
    padding-bottom: 5px;
    margin-bottom: 5px;
    &:last-child {
      border: 0;
    }
  }
}

.modal-backdrop {
  background-color: #389e87;
}

.modal-content {
  background-color: #ef4035;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  .modal-header {
    display: flex;
    justify-content: space-between;
    color: #fff;
    border: none;
    &:before {
      display: none;
    }
    &:after {
      content: none;
    }
  }
  .modal-body {
  }
  .modal-footer {
    border: none;
    .btn-primary {
      width: 100%;
      color: #fff;
      border: 0px;
      text-align: center;
      box-sizing: border-box;
      padding: 3px 50px 6px 50px;
      line-height: 35px;
      height: 44px;
      font-size: 20px;
      background-color: #008265 !important;
      font-weight: 700;
      background: url(https://www.ekompany.eu/images/iconen/icoon.submit.png) right 15px center no-repeat #ef4035;
      cursor: pointer;
      overflow: hidden;
    }
  }
}
.diller-box {
  max-width: 1380px;
  margin: 0 auto;
  height: auto;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 15px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: 40px;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  img {
    margin-right: 50px;
      @media (max-width: 600px) {
        margin-right: 0px;
        margin-bottom: 30px;
    }
  }
  .links-wrap {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: #008265;
    padding: 40px 20px 40px 30px;
    p {
        display: block;
        color: rgba(255,255,255,0.7);
        font-size: 20px;
        font-family: "Vag Rounded",arial;
        text-decoration: none;
        padding: 4px 0px 4px 20px;
        transition: .2s ease;
        background: url(https://www.ekompany.eu/images/iconen/icoon.productdetail.links.png) left center no-repeat;
        cursor: pointer;
        overflow: hidden;
        &:hover {
          color: rgba(255,255,255,1);
        }
    }
  }
}

.modal-diller-input {
  display: block;
  width: 100%;
  height: 35px;
  border: none;
  padding-left: 15px;
  padding-right: 20px;
  margin-bottom: 7px;
  border-radius: 4px;
  cursor: pointer;
  &::placeholder {
    color: #389e87;
    font-weight: bold;
  }
}

.diller-textarea {
  height: 100px;
  width: 100%;
  border: none;
  padding-left: 15px;
  border-radius: 4px;
  cursor: pointer;
  &::placeholder {
    color: #389e87;
    font-weight: bold;
  }
  &:focus {
    outline: none;
  }
}