.footer-main {
  background-color: #E25252;
  height: 90px;
  font-size: 16px;
  justify-content: center;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  // padding-left: 50px;
  // padding-right: 50px;
  .footer-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;
    max-width: 1380px;
  }
}