/* All modules reusable parts ------------------------
 ----------------------------------------------------- */
/* Links & Buttons */
.btn-main {
  @include transition-main;

  &--round {
    border-radius: 25px;
    padding: 12px 24px;
    font-size: 1.06rem;
    line-height: 1.75;
    font-weight: $fw-bold;

    &.is-round--large {
      border-radius: 32px;
      padding: 18px 40px;
    }
  }

  &--primary {
    color: $accent-dark-cl;
    background: $white-cl;

    &:hover {
      background: #F8FDFC;
    }
  }

  &--alt {
    color: $white-cl;
    background: $accent-dark-cl;

    &:hover {
      background: $accent-light-cl;
    }
  }

  &--shadow {
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.13);
  }
}
